import React, { Fragment } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    const postsByYear = this.postsByYear(posts);

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Blog" keywords={[`blog`, `programming`]} />
        {Object.keys(postsByYear).reverse().map((year) => (
          <Fragment key={year}>
            <h4>{year}</h4>
            {postsByYear[year].map((node) => (
              <div key={node.fields.slug}>
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {node.frontmatter.title || node.fields.slug}
                </Link>{" "}
                <small>{node.frontmatter.date}</small>
              </div>
            ))}
          </Fragment>
        ))}
      </Layout>
    )
  }

  postsByYear(posts) {
    const years = {};

    posts.forEach(({ node }) => {
      const year = node.frontmatter.year;
      years[year] = years[year] || [];
      years[year].push(node);
    });

    return years;
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD/MM")
            year: date(formatString: "YYYY")
            title
          }
        }
      }
    }
  }
`
